import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import OtherLoginMethodsComponent from "../OtherLoginMethods";
import Input from "components/Input";
import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import UnauthenticatedFormContainerComponent from "../UnauthenticatedFormContainer";

const LogInFormComponent: React.FC<any> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [username, updateUsername] = useState("");
  const [password, updatePassword] = useState("");
  const [passwordError, updatePasswordError] = useState("");
  const goToSignUpRoute = () => {
    history.push({
      pathname: "signup",
    });
  };

  const passwordValidator = (newPassword: string) => {
    const test = /^[A-Za-z0-9!@#\$%\^\&*\)\(+=._-]+$/.test(newPassword);
    if (!test && newPassword !== "") {
      updatePasswordError("Mật khẩu tầm bậy.");
    } else {
      updatePasswordError("");
    }
    updatePassword(newPassword);
  };

  return (
    <UnauthenticatedFormContainerComponent
      appName={t("common:app_name")}
      title={t("common:login.title")}
      description={t("common:login.description")}
      recommend={t("common:login.recommend")}
      submitText={t("common:login.form.submit_button.text")}
      onClickRecommend={goToSignUpRoute}
    >
      <div>
        <Input
          testId="username"
          placeholder={t("common:login.form.username.placeholder")}
          value={username}
          onChange={(v) => updateUsername(v)}
        />
        <Input
          testId="password"
          isPassword
          placeholder={t("common:login.form.password.placeholder")}
          value={password}
          onChange={passwordValidator}
          isError={passwordError != ""}
        />
        {passwordError != "" && (
          <div className={styles.error}>{passwordError}</div>
        )}
      </div>
    </UnauthenticatedFormContainerComponent>
  );
};

export default LogInFormComponent;
