import React from "react";
import { UnauthenticatedType } from "../../models";
import SignUpFormComponent from "./SignUpForm";
import bannerPhoto from "assets/banner.jpg";
import styles from "./styles.module.scss";
import LogInFormComponent from "./LoginForm";

interface Props {
  type: UnauthenticatedType;
}

const UnauthenticatedComponent: React.FC<Props> = ({ type }) => {
  if (type === "LOGIN") {
    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <img className={styles.img} src={bannerPhoto} />
        </div>
        <div className={styles.right}>
          <LogInFormComponent />
        </div>
        <div className={styles.note}>
          Mật khẩu cần thêm số với kí tự đặc biệt nhé. A Tú Trung Đức Hùng nói
          vậy!
        </div>
      </div>
    );
  }
  if (type === "SIGN_UP") {
    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <img className={styles.img} src={bannerPhoto} />
        </div>
        <div className={styles.right}>
          <SignUpFormComponent />
        </div>
        <div className={styles.note}>
          Mật khẩu cần thêm số với kí tự đặc biệt nhé. A Tú Trung Đức Hùng nói
          vậy!
        </div>
      </div>
    );
  }
  return <div className={styles.container}>Unauthenticated</div>;
};

export default UnauthenticatedComponent;
