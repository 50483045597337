import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { RootState } from '../models';

interface Props {
  children: any;
}

const PrivateRoute: React.FC<Props> = ({ children }: any) => {
  const auth = useSelector((state: RootState) => state.auth);
  return (
    <>
      <Route
        render={({ location }) =>
          auth.isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )
        }
      />
    </>
  );
};

export default PrivateRoute;
