import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

export const AboutPage: React.FC = () => {
  const history = useHistory();

  return (
    <Fragment>
      <h1>Giới thiệu</h1>
      <p>OK CC</p>
      <button
        type="button"
        className="btn"
        cy-data="go-back-button"
        onClick={() => history.push("/")}
      >
        Quay lại
      </button>
    </Fragment>
  );
};
