import React from "react";
import { useTranslation } from "react-i18next";
import appleIcon from "assets/icon-apple-3x.png";
import facebookIcon from "assets/icon-facebook-3x.png";
import googleIcon from "assets/icon-google-3x.png";
import appStoreIcon from "assets/icon-app-store-3x.png";
import googlePlayIcon from "assets/icon-google-play-3x.png";

import styles from "./styles.module.scss";

const OtherLoginMethodsComponent: React.FC<any> = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.forgetPassword}>
        {t("common:gateway.forget_password.text")}
      </div>
      <div className={styles.dashContainer}>
        <div className={styles.dash}></div>
        <div className={styles.or}>{t("common:gateway.or")}</div>
      </div>

      <div className={styles.signInMethods}>
        <div className={styles.appleIcon}>
          <img className={styles.img} src={appleIcon} />
        </div>
        <div className={styles.facebookIcon}>
          <img className={styles.img} src={facebookIcon} />
        </div>
        <div className={styles.googleIcon}>
          <img className={styles.img} src={googleIcon} />
        </div>
      </div>
      <div className={styles.downloadApps}>
        <div className={styles.downloadAppText}>
          {t("common:gateway.download_app.text")}
        </div>
        <div className={styles.downloadAppIcons}>
          <div className={styles.appStoreIcon}>
            <img className={styles.img} src={appStoreIcon} />
          </div>
          <div className={styles.googlePlayIcon}>
            <img className={styles.img} src={googlePlayIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherLoginMethodsComponent;
