import { reducerWithInitialState } from 'typescript-fsa-reducers'
import {
	getCachedAuthAction,
	setAuthStatusAction,
	signupAsyncAction,
} from './action'
import { AuthState } from '../../models'

const initialState: AuthState = {
	loading: false,
	isAuthenticated: false,
}

export const AuthReducer = reducerWithInitialState(initialState)
	.case(getCachedAuthAction, (state) => {
		const cachedUser = localStorage.getItem('user')
		if (cachedUser) {
			return {
				...state,
				user: cachedUser,
			}
		}
		return state
	})
	.case(setAuthStatusAction, (state, newStatus) => {
		return {
			...state,
			isAuthenticated: newStatus,
		}
	})
	.case(signupAsyncAction.async.started, (state) => {
		return {
			...state,
			loading: true,
		}
	})
	.case(signupAsyncAction.async.done, (state, result) => {
		return {
			...state,
			user: result,
		}
	})
	.case(signupAsyncAction.async.failed, (state, errorMessage) => {
		const msg = String(errorMessage)
		return {
			...state,
			errorMessage: msg,
		}
	})
