import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { SignupRequest } from '../../models';

const createAction = actionCreatorFactory();
const createAsyncAction = asyncFactory<any>(createAction);

export const getCachedAuthAction = createAction('GET_CACHED_AUTH');
export const setAuthStatusAction = createAction<any>('SET_AUTH_STATUS_ACTION');

export const signupAsyncAction = createAsyncAction<SignupRequest, any, any>(
    'SIGN_UP',
    async (params, dispatch) => {
        console.log('signupAsyncAction start', params);
        const mockApi = new Promise<boolean>((resolve) => {
            setTimeout(() => {
                resolve(true);
            }, 3000);
        });
        const signUp = await mockApi;
        if (signUp) {
            dispatch(setAuthStatusAction(signUp));
            return signUp;
        }
        throw new Error('Signup failed');
    }
);

export type AuthActionType =
    | typeof getCachedAuthAction
    | typeof setAuthStatusAction
    | typeof signupAsyncAction;
