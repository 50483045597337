import React from "react";
import { Route, Switch } from "react-router-dom";
import { AboutPage } from "../pages/About";
import { HomePage } from "../pages/Home";
import LoginPage from "../pages/Login";
import SignupPage from "../pages/Signup";
import PrivateRoute from "./PrivateRoute";

const Router: React.FC<any> = () => {
  return (
    <>
      <Switch>
        <Route path="/signup" component={SignupPage} />
        <Route path="/login" component={LoginPage} />
        <PrivateRoute>
          <Route path="/" component={HomePage} exact />
          <Route path="/about" component={AboutPage} />
        </PrivateRoute>
        {/* </PrivateRoute> */}
        {/* <Route path="/signup" component={SignupPage} /> */}
        {/* <Route path="/login" component={LoginPage} /> */}
      </Switch>
    </>
  );
};

export default Router;
