import { combineReducers, createStore, applyMiddleware, AnyAction } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk'
import { AuthReducer } from './features/auth/reducer'
import { RootState } from './models'

const thunk: ThunkMiddleware<RootState, AnyAction> = thunkMiddleware
const rootReducer = combineReducers<RootState>({
  auth: AuthReducer,
})

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

export default store
