import React from "react";
import OtherLoginMethodsComponent from "../OtherLoginMethods";
import styles from "./styles.module.scss";

interface Props {
  appName: string;
  title: string;
  description: string;
  recommend: string;
  onClickRecommend: () => void;
  submitText: string;
}

const UnauthenticatedFormContainerComponent: React.FC<Props> = ({
  appName,
  title,
  description,
  recommend,
  onClickRecommend,
  submitText,
  children,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.appName}>{appName}</div>
      <div className={styles.title}>{title}</div>
      <div className={styles.more}>
        <div className={styles.description}>{description}</div>
        <div className={styles.recommend} onClick={onClickRecommend}>
          {recommend}
        </div>
      </div>
      <form>
        {children}
        <div>
          <button className={styles.submitBtn} type="submit">
            {submitText}
          </button>
        </div>
      </form>
      <OtherLoginMethodsComponent />
    </div>
  );
};

export default UnauthenticatedFormContainerComponent;
