import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import OtherLoginMethodsComponent from "../OtherLoginMethods";
import Input from "components/Input";
import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import UnauthenticatedFormContainerComponent from "../UnauthenticatedFormContainer";

const SignUpFormComponent: React.FC<any> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [displayName, updateDisplayName] = useState("");
  const [username, updateUsername] = useState("");
  const [password, updatePassword] = useState("");
  const [confirmPassword, updateConfirmPassword] = useState("");

  const goToLoginRoute = () => {
    history.push({
      pathname: "login",
    });
  };
  return (
    <UnauthenticatedFormContainerComponent
      appName={t("common:app_name")}
      title={t("common:signup.title")}
      description={t("common:signup.description")}
      recommend={t("common:signup.recommend")}
      submitText={t("common:signup.form.submit_button.text")}
      onClickRecommend={goToLoginRoute}
    >
      <div>
        <Input
          testId="displayname"
          placeholder={t("common:signup.form.display_name.placeholder")}
          value={displayName}
          onChange={(v) => updateDisplayName(v)}
        />
        <Input
          testId="username"
          placeholder={t("common:signup.form.username.placeholder")}
          value={username}
          onChange={(v) => updateUsername(v)}
        />
        <Input
          testId="password"
          isPassword
          placeholder={t("common:signup.form.password.placeholder")}
          value={password}
          onChange={(v) => updatePassword(v)}
        />
        <Input
          testId="confirmpassword"
          isPassword
          placeholder={t("common:signup.form.confirm_password.placeholder")}
          value={confirmPassword}
          onChange={(v) => updateConfirmPassword(v)}
        />
      </div>
    </UnauthenticatedFormContainerComponent>
  );
};

export default SignUpFormComponent;
