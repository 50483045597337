import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

interface Props {
  testId?: string;
  placeholder?: string;
  value?: string;
  onChange?: (newValue: string) => void;
  isPassword?: boolean;
  isError?: boolean;
}

const Input: React.FC<Props> = ({
  testId,
  value,
  onChange,
  isPassword = false,
  isError = false,
  placeholder = "...",
}) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(!!value);
  const [displayPassword, updateDisplayPassword] = useState(isPassword);
  const inputRef = useRef<any>(null);
  const onFocus = () => {
    if (!value) setActive(true);
    inputRef?.current?.focus();
  };
  const onBlur = () => {
    if (!value) setActive(false);
    inputRef?.current?.blur();
  };
  const onInputChange = (e: any) => {
    onChange && onChange(e.target.value);
  };
  const onPressShowPassword = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    updateDisplayPassword(true);
  };
  const onPressHidePassword = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    updateDisplayPassword(false);
  };
  const inputType = displayPassword ? "password" : "text";
  return (
    <div
      className={`${styles.container}  ${active ? styles.active : ""} ${
        isError ? styles.error : ""
      } ${active && !isError ? styles.valid : ""}`}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseEnter={onFocus}
      onMouseLeave={onBlur}
      onClick={onFocus}
    >
      <div className={`${styles.label}`} onClick={onFocus}>
        {placeholder}
      </div>
      <input
        ref={inputRef}
        type={inputType}
        className={`${styles.input}`}
        data-testid={testId}
        value={value}
        onChange={onInputChange}
      />
      {isPassword && (
        <div className={styles.displayPassword}>
          {!displayPassword && (
            <button
              className={styles.showPassword}
              onClick={onPressShowPassword}
            >
              {t("common:input.showPassword")}
            </button>
          )}
          {displayPassword && (
            <button
              className={styles.hidePassword}
              onClick={onPressHidePassword}
            >
              {t("common:input.hidePassword")}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Input;
