import React, { Fragment } from "react";

export const HomePage: React.FC = () => {
  return (
    <Fragment>
      <h1>BankBank CC</h1>
      <p>Vay mượn tiền gì vào đây.</p>
    </Fragment>
  );
};
