import React from "react";
import UnauthenticatedComponent from "../../components/Unauthenticated";

const LoginPage: React.FC<any> = () => {
  return (
    <div>
      <UnauthenticatedComponent type={"LOGIN"} />
    </div>
  );
};

export default LoginPage;
